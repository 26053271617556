body{
    color: #888888;
    margin-top:20px;}
.progress {
    position: relative;
    overflow: inherit;
    height: 6px;
    margin: 30px 0px 15px;
    width: 100%;
    display: inline-block;
    border-radius: 10px;
}

.progress .progress-bar {
    height: 6px;
    background: #009b72;
    border-radius: 10px;
}

.progress .progress-bar-title {
    position: absolute;
    left: 0;
    top: -30px;
    color: #818181;
    font-size: 16px;
}

.progress .progress-bar-number {
    position: absolute;
    right: 0;
    color: #888888;
    top: -30px;
    font-weight: 600;
    font-size: 14px;
}
.media {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.text-black {
    color: #000000;
}

.font-weight-normal {
    font-weight: 500 !important;
}
.w-25 {
    width: 25% !important;
}
.text-muted {
    color: #b2b2b2 !important;
}
.mr-1, .mx-1 {
    margin-right: 0.625rem !important;
}